import React, { useEffect, useState } from "react";
import "./result.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";



const SearchResult = () => {
  const [programs, setPrograms] = useState([]);

const navitate =useNavigate();
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          "https://secure.7college.du.ac.bd/ReactApis/get_pid"
        );
        setPrograms(response.data.programs);
      } catch (err) {
       
      } finally {
        
      }
    };
    fetchPrograms();
  }, []);

  const [selectedProgram, setSelectedProgram] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [yers, setYears] = useState([]);
  const [exams, setExams] = useState([]);

  const selectProgram = async (program_id) => {
    setSelectedProgram(program_id);
   
   
    try {
      const response = await axios.get(
        `https://secure.7college.du.ac.bd/ReactApis/get_yid?program_id=${program_id}`
      );
      setYears(response.data.years);
    } catch (err) {
     
    }
  };
  const yearChange = async (year_id) => {
    setSelectedYear(year_id);
    try {
      const response = await axios.get(
        `https://secure.7college.du.ac.bd/ReactApis/get_eid?program_id=${selectedProgram}&yid=${year_id}`
      );
      setExams(response.data.exams);
    } catch (err) {
     
    }
  };
  const [selectedExam, setSelectedExam] = useState();
  const [reg, setReg] = useState(18723020193);
  const [roll, setRoll] = useState();
  const getResult = async () => {
    try {
      const response = await axios.get(`https://secure.7college.du.ac.bd/ReactApis/get_result?program_id=${selectedProgram}&yid=${selectedYear}&eid=${selectedExam}&reg=${reg}&roll=${roll}`)
       
    if(response.data.status === 'success'){
      navitate('./results', {state:{resultData:response?.data?.result}})
    }else{
      alert('somthing want worng.')
    }
    } catch (err) {
      
    }
  };
  return (
    <div className="registration-form">
      <div
        className="registration-form-header"
        style={{ backgroundColor: "#513c72" }}
      >
        <img
          className="logo"
          src="https://result.7college.du.ac.bd/rokey/logo.png"
          alt="logo"
        />
        <h4 style={{ color: "white", fontWeight: "bold", fontSize: "20px",margin: "5px 0" }}>
          7 Colleges Affiliated with University of Dhaka
        </h4>
        <h5 style={{ color: "white", fontWeight: "bold", fontSize: "18px",margin: "5px 0" }}>
          Result Archive
        </h5>
      </div>
      <div style={{ textAlign: "left" }}>
        <label style={{ padding: "10px" }}>Select Program:</label>
        <select
          name="program"
          style={{ textAlign: "left", fontSize:16 }}
          className="form-control"
          onChange={(e) => selectProgram(e.target.value)}
          value={selectedProgram}
          required
        >
          <option style={{ padding: "10px" }} value="">
            Select Program
          </option>
          {programs?.map((program) => (
            <option key={program.id} value={program.id}>
              {program.program_name}
            </option>
          ))}
        </select>
      </div>
      <div style={{ textAlign: "left" }}>
        <label>Select Year:</label>
        <select
          name="year"
          style={{ textAlign: "left", fontSize:16 }}
          className="form-control"
          onChange={(e) => yearChange(e.target.value)}
          value={selectedYear}
          required
        >
         <option style={{ padding: "10px" }} value="">
            Select Year
          </option>
          {yers?.map((year) => (
            <option key={year.id} value={year.id}>
              {year.year_name}
            </option>
          ))}
        </select>
      </div>
      <div style={{ textAlign: "left" }}>
        <label style={{ padding: "5px" }}>Select Exam:</label>
        <select name="exam"
        style={{ textAlign: "left", fontSize:16 }}
         className="form-control"
        onChange={(e)=> setSelectedExam(e.target.value)}
        value={selectedExam}
        
        required>
           <option style={{ padding: "10px" }} value="">
            Select Exam
          </option>
          {exams?.map((exam)=>(
            <option key={exam.id } value={exam.id}>
              {exam.exam_title}
            </option>
          ))}
     
        </select>
      </div>
      <div style={{ textAlign: "left" }}>
        <label>Registration Number:</label>
        <input
          type="number"
          name="registrationNumber"
          style={{ textAlign: "left", fontSize:16 }}
          value={reg}
          onChange={(e) => setReg(e.target.value)}
          className="form-control"
          required
        />
      </div>
      <div style={{ textAlign: "left" }}>
        <label>Exam Roll:</label>
        <input
          type="number"
          name="examRoll"
          style={{ textAlign: "left", fontSize:16 }}
          value={roll}
          onChange={(e)=>setRoll(e.target.value)}
          className="form-control"
          required
        />
      </div>
      <div style={{ textAlign: "center", color: "white" }}>
        <button
          style={{ backgroundColor: "#513c72", color: "white" ,fontSize:20}}
          type="submit"
          onClick={getResult}
        >
          Search
        </button>
      </div>
      <div
        style={{
          backgroundColor: "#513c72",
          height: 100,
          marginTop: 20,
          width: "auto",
          borderRadius: "5px",
        }}
      >
        <div className="footer-text" style={{textAlign:'center',fontSize:17}}>
        @ 𝟐𝟎𝟐𝟒 𝐔𝐧𝐢𝐯𝐞𝐫𝐬𝐢𝐭𝐲 𝐨𝐟 𝐃𝐡𝐚𝐤𝐚
          <br />
      
          𝐃𝐞𝐯𝐞𝐥𝐨𝐩𝐞𝐝 𝐛𝐲 𝐌𝐚𝐢𝐧𝐭𝐞𝐧𝐚𝐧𝐜𝐞 𝐎𝐟𝐟𝐢𝐜𝐞 𝐨𝐟 𝐭𝐡𝐞 𝐂𝐨𝐧𝐭𝐫𝐨𝐥𝐥𝐞𝐫 𝐨𝐟 𝐄𝐱𝐚𝐦𝐢𝐧𝐚𝐭𝐢𝐨𝐧
           </div>
      </div>
    </div>
  );
};

export default SearchResult;
